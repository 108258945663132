import { HydratedRouter } from "react-router/dom";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { startTransition, StrictMode } from "react";
import { getServerClient } from "./graphql";
import { ApolloProvider } from "@apollo/client";

if (webConfig.environment === "production") {
  Sentry.init({
    dsn: "https://a66fdc66195f43b5af44bf4af297d1fb@o457803.ingest.us.sentry.io/6599348",
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: webConfig.environment,
    release: webConfig.revision,
    integrations: [
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    // ...
  });
}

function eventHandler(event: any) {
  try {
    event.preventDefault();
    installPrompt = event;
  } catch (error) {
    console.error(error);
  }
}

window.addEventListener("beforeinstallprompt", eventHandler);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/sw.js");
  });
}

startTransition(() => {
  const client = getServerClient();
  hydrateRoot(
    document,
    <StrictMode>
      <ApolloProvider client={client}>
        <HydratedRouter />
      </ApolloProvider>
    </StrictMode>,
  );
});
